export const mailingList = [
  "mike@roboticimaging.com",
  "nick@roboticimaging.com",
  "joe@roboticimaging.com",
  "will@roboticimaging.com",
  "matt@roboticimaging.com",
  "austin@roboticimaging.com",
  "denton@roboticimaging.com",
  "mike.hepp@roboticimaging.com",
  "daniel@roboticimaging.com",
  "brian@roboticimaging.com",
  "anya@roboticimaging.com",
];


export const emailSignUpList = [
  "info@roboticimaging.com",
  "bim@roboticimaging.com",
  "ops@roboticimaging.com",
  "sales@roboticimaging.com",
  "software@roboticimaging.com"
]
import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import "../scss/alt.css";
import { graphql } from "gatsby";
import { CostCalculator } from "../components/costCalculator";
import { InlineWidget } from "react-calendly";
import { ACTIVE_PAGE } from "../components/nav/utils";

export const query = graphql`
  query ($slug: String!) {
    allEstimatesJson(filter: { href: { eq: $slug } }) {
      edges {
        node {
          title
          href
          headerSrc
          breakpoints
          exterior
          interior {
            PointCloud
            FloorPlansAndElevations
            RevitModel
            MatterportVirtualTour
          }
          Drone
        }
      }
    }
  }
`;

const Page = ({ data }: { data: any }) => {
  const {
    allEstimatesJson: { edges },
  } = data;

  const { title, headerSrc, breakpoints, exterior, interior, Drone } =
    edges[0].node;

  const priceInfo = { breakpoints, exterior, interior, Drone };

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.SQFTESTIMATE}>
      <Seo title="Partner Pricing | Robotic Imaging" />
      <header className="site__header">
        <h1 id="pageHeaders" className="header__motto header__motto--no-margin">
          {`${title}`}
        </h1>
      </header>
      <main className="content">
        <div className="estimates__content">
          <CostCalculator
            company={title}
            imgSrc={headerSrc}
            priceInfo={priceInfo}
          />
          <InlineWidget
            url="https://calendly.com/roboticimaging"
            styles={{
              backgroundColor: "white",
            }}
          />
        </div>
      </main>
    </SiteWrapper>
  );
};

export default Page;

import React, { useEffect, useState, useRef } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
// import useOnclickOutside from "react-cool-onclickoutside";
import axios from "axios";
import { mailingList } from "../utils/vars";

export const CostCalculator = ({ company, imgSrc, priceInfo }) => {
  const [sqft, setSqft] = useState(0);
  const [address, setAddress] = useState("");
  const [interiorChecked, setInteriorChecked] = useState(false);
  const [exteriorChecked, setExteriorChecked] = useState(false);
  const [pointCloudChecked, setPointCloudChecked] = useState(false);
  const [floorPlansElevationsChecked, setFloorPlansElevationsChecked] =
    useState(false);
  const [revitModelChecked, setRevitModelChecked] = useState(false);
  const [matterportChecked, setMatterportChecked] = useState(false);
  const [aerialChecked, setAerialChecked] = useState(false);

  const { breakpoints, exterior, interior, Drone } = priceInfo;
  const {
    PointCloud,
    FloorPlansAndElevations,
    RevitModel,
    MatterportVirtualTour,
  } = interior;

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const findBreakpoint = (squareFt) => {
    for (let i = 0; i < breakpoints.length; i++) {
      if (squareFt >= breakpoints[i][0] && squareFt <= breakpoints[i][1])
        return i;
    }
    return breakpoints.length - 1;
  };

  const uncheckAll = () => {
    setRevitModelChecked(false);
    setFloorPlansElevationsChecked(false);
    setPointCloudChecked(false);
    setMatterportChecked(false);
    setAerialChecked(false);
  };
  const [currIdx, setCurrIdx] = useState(null);
  const inputCache = useRef("");
  // const ref = useOnclickOutside(() => {
  // 	// When user clicks outside of the component, we can dismiss
  // 	// the searched suggestions by calling this method
  // 	clearSuggestions();
  // });
  const dismissSuggestions = () => {
    setCurrIdx(null);
    clearSuggestions();
  };
  const handleSelect = ({ description }) => {
    setValue(description, false);
    dismissSuggestions();
  };
  useEffect(() => {
    setAddress(value);
  }, [value, setAddress]);

  const setMatterportAerial = (next) => {
    if (!next) {
      setMatterportChecked(false);
      setAerialChecked(false);
    } else {
      if (interiorChecked) setMatterportChecked(true);
      if (exteriorChecked) setAerialChecked(true);
    }
  };

  // Checking/unchecking interior/exterior while a bundle is selected
  const intExtWhileBundleSelected = (intExt, prev) => {
    if (revitModelChecked || floorPlansElevationsChecked || pointCloudChecked) {
      // Interior
      if (intExt === "Interior") {
        // Previous state checked, will be unchecked after click
        if (prev) setMatterportChecked(false);
        // Previous state unchecked, will be checked after click
        else setMatterportChecked(true);
      }
      // Exterior
      else if (intExt === "Exterior") {
        // Previous state checked, will be unchecked after click
        if (prev) setAerialChecked(false);
        // Previous state unchecked, will be checked after click
        else setAerialChecked(true);
      }
    }
  };

  const buildReceipt = (revit, floor, point, matter, drone) => {
    const RevitText = () => (
      <p>
        <a href="/3D" target="_blank">
          3D Revit Model
        </a>
      </p>
    );
    const FloorText = () => (
      <p>
        <a href="/2D" target="_blank">
          2D Floor Plans & Elevations
        </a>
      </p>
    );
    const PointText = () => (
      <p>
        <a href="/point-cloud" target="_blank">
          LiDAR Point Cloud
        </a>
      </p>
    );
    const MatterText = () => (
      <p>
        <a href="/vr" target="_blank">
          Matterport VR Walkthrough
        </a>
      </p>
    );
    const DroneText = () => <p>Drone Aerials</p>;
    const MatterDrone = () => (
      <>
        {matter ? <MatterText /> : ""}
        {drone ? <DroneText /> : ""}
      </>
    );
    if (revit)
      return (
        <>
          <p className="estimates__receipt__header">Scan-to-3D Includes:</p>
          <RevitText /> <FloorText /> <PointText /> <MatterDrone />
        </>
      );
    else if (floor)
      return (
        <>
          <p className="estimates__receipt__header">Scan-to-2D Includes:</p>
          <FloorText /> <PointText /> <MatterDrone />
        </>
      );
    else if (point)
      return (
        <>
          <p className="estimates__receipt__header">
            Scan-to-Point Cloud Includes:
          </p>
          <PointText /> <MatterDrone />
        </>
      );
    else if (matter || drone) return <MatterDrone />;
    else return "";
  };

  const calculateCost = (squareFt) => {
    let total = 0;
    const idx = findBreakpoint(squareFt);
    if (revitModelChecked) {
      if (interiorChecked && exteriorChecked)
        total += RevitModel[idx] * squareFt + exterior[idx] * squareFt;
      else if (interiorChecked) total += RevitModel[idx] * squareFt;
      else if (exteriorChecked) total += exterior[idx] * squareFt * 2;
    } else if (floorPlansElevationsChecked) {
      if (interiorChecked && exteriorChecked)
        total +=
          FloorPlansAndElevations[idx] * squareFt + exterior[idx] * squareFt;
      else if (interiorChecked)
        total += FloorPlansAndElevations[idx] * squareFt;
      else if (exteriorChecked) total += exterior[idx] * squareFt * 2;
    } else if (pointCloudChecked) {
      if (interiorChecked && exteriorChecked)
        total += PointCloud[idx] * squareFt + exterior[idx] * squareFt;
      else if (interiorChecked) total += PointCloud[idx] * squareFt;
      else if (exteriorChecked) total += exterior[idx] * squareFt * 2;
    } else if (matterportChecked || aerialChecked) {
      if (matterportChecked) {
        total += MatterportVirtualTour[idx] * squareFt;
      }
      if (aerialChecked) {
        total += Drone[idx];
      }
    }
    return (total * 0.7).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="estimates__calculator-container">
      {/* <img
				src={imgSrc.childImageSharp.gatsbyImageData.images.fallback.src}
				alt=""
				className="estimates__logo"
			/> */}
      <form>
        <div className="estimates__calculator">
          <div className="estimates__flex-wrapper">
            <div className="estimates__option-container">
              <div className="estimates__option">
                <input
                  type="checkbox"
                  checked={interiorChecked}
                  onChange={() =>
                    setInteriorChecked((prev) => {
                      if (prev && !exteriorChecked) uncheckAll();
                      if (prev && matterportChecked)
                        setMatterportChecked(false);
                      intExtWhileBundleSelected("Interior", prev);
                      return !prev;
                    })
                  }
                  className="estimates__grid__right estimates__checkbox"
                />
                <span>Interior</span>
              </div>
              <div className="estimates__option">
                <input
                  type="checkbox"
                  checked={exteriorChecked}
                  onChange={() =>
                    setExteriorChecked((prev) => {
                      if (prev && !interiorChecked) uncheckAll();
                      if (prev && aerialChecked) setAerialChecked(false);
                      intExtWhileBundleSelected("Exterior", prev);
                      return !prev;
                    })
                  }
                  className="estimates__grid__right estimates__checkbox"
                />
                <span>Exterior</span>
              </div>

              <div className="estimates__option">
                <input
                  type="checkbox"
                  checked={revitModelChecked}
                  disabled={!interiorChecked && !exteriorChecked}
                  onChange={() =>
                    setRevitModelChecked((prev) => {
                      setPointCloudChecked(!prev);
                      setFloorPlansElevationsChecked(!prev);
                      setMatterportAerial(!prev);
                      return !prev;
                    })
                  }
                  className="estimates__grid__right estimates__checkbox"
                />
                <span>Scan-to-3D Revit Model</span>
              </div>

              <div className="estimates__option">
                <input
                  type="checkbox"
                  checked={floorPlansElevationsChecked}
                  disabled={
                    revitModelChecked || (!interiorChecked && !exteriorChecked)
                  }
                  onChange={() =>
                    setFloorPlansElevationsChecked((prev) => {
                      setPointCloudChecked(!prev);
                      setMatterportAerial(!prev);
                      return !prev;
                    })
                  }
                  className="estimates__grid__right estimates__checkbox"
                />
                <span>Scan-to-2D Floor Plans & Elevations</span>
              </div>

              <div className="estimates__option">
                <input
                  type="checkbox"
                  checked={pointCloudChecked}
                  disabled={
                    revitModelChecked ||
                    floorPlansElevationsChecked ||
                    (!interiorChecked && !exteriorChecked)
                  }
                  onChange={() =>
                    setPointCloudChecked((prev) => {
                      setMatterportAerial(!prev);
                      return !prev;
                    })
                  }
                  className="estimates__grid__right estimates__checkbox"
                />
                <span>Scan-to-Point Cloud</span>
              </div>

              <div
                className="estimates__option"
                style={{ visibility: interiorChecked ? "visible" : "hidden" }}
              >
                <input
                  type="checkbox"
                  checked={matterportChecked}
                  disabled={
                    revitModelChecked ||
                    floorPlansElevationsChecked ||
                    pointCloudChecked ||
                    !interiorChecked
                  }
                  onChange={() => setMatterportChecked((prev) => !prev)}
                  className="estimates__grid__right estimates__checkbox"
                />
                <span>Matterport Virtual Tour</span>
              </div>

              <div
                className="estimates__option"
                style={{ visibility: exteriorChecked ? "visible" : "hidden" }}
              >
                <input
                  type="checkbox"
                  checked={aerialChecked}
                  disabled={
                    revitModelChecked ||
                    floorPlansElevationsChecked ||
                    pointCloudChecked ||
                    !exteriorChecked
                  }
                  onChange={() => setAerialChecked((prev) => !prev)}
                  className="estimates__grid__right estimates__checkbox"
                />
                <span>Aerial Drone Flight</span>
              </div>
            </div>

            <div className="estimates__receipt">
              <h2 className="estimates__receipt__title">Deliverables</h2>
              <p className="estimates__receipt__header">
                Scan type:&nbsp;
                {!interiorChecked && !exteriorChecked
                  ? "Not Selected"
                  : interiorChecked && exteriorChecked
                  ? "Interior & Exterior"
                  : interiorChecked
                  ? "Interior"
                  : "Exterior"}
              </p>
              {buildReceipt(
                revitModelChecked,
                floorPlansElevationsChecked,
                pointCloudChecked,
                matterportChecked,
                aerialChecked
              )}
            </div>
          </div>

          <div className="estimates__grid">
            <span>Total Square Footage Estimate:</span>
            <input
              className="text-input signup__input estimates__input estimates__grid__right"
              type="number"
              placeholder="SQFT estimate..."
              onChange={(e) => {
                setSqft(e.target.value);
              }}
            />
            <span>Site Address:</span>
            <div>
              <input
                className="text-input signup__input estimates__input estimates__grid__right"
                type="text"
                placeholder="123 Main St..."
                // onChange={(e) => { setAddress(e.target.value) }}
                value={value}
                disabled={!ready}
                onChange={(e) => {
                  setValue(e.target.value);
                  inputCache.current = e.target.value;
                  setCurrIdx(null);
                }}
                onKeyDown={(e) => {
                  // Prevents form submit on enter
                  if (e.key === "Enter") e.preventDefault();
                  // Goes through suggestions and sets value accordingly
                  if (
                    data.length &&
                    (e.key === "ArrowDown" || e.key === "ArrowUp")
                  ) {
                    console.log(data.length);
                    e.preventDefault();
                    let nextIdx;
                    if (e.key === "ArrowUp") {
                      nextIdx = currIdx ?? data.length;
                      nextIdx = nextIdx > 0 ? nextIdx - 1 : null;
                    } else {
                      nextIdx = currIdx ?? -1;
                      nextIdx = nextIdx < data.length - 1 ? nextIdx + 1 : null;
                    }
                    setCurrIdx(nextIdx);
                    const newVal = data[nextIdx]
                      ? data[nextIdx].description
                      : inputCache.current;
                    setValue(newVal, false);
                  }
                }}
                onKeyUp={(e) => {
                  //  Dismiss suggestions when pressing enter and a suggestion is selected
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (currIdx !== null) dismissSuggestions();
                  }
                }}
              />
              {status === "OK" && (
                <div
                  className="requestForm__suggestion-container estimates__suggestion-container"
                  onMouseLeave={() => setCurrIdx(null)}
                  role="listbox"
                  tabIndex={0}
                >
                  {data.map((suggestion, idx) => {
                    const {
                      place_id,
                      structured_formatting: { main_text, secondary_text },
                    } = suggestion;

                    return (
                      <div
                        key={place_id}
                        onClick={() => handleSelect(suggestion)}
                        onKeyUp={(e) => {
                          if (e.key === "Enter") handleSelect(suggestion);
                        }}
                        className={
                          idx === currIdx
                            ? "requestForm__suggestion requestForm__suggestion-current"
                            : "requestForm__suggestion"
                        }
                        onMouseEnter={() => setCurrIdx(idx)}
                        role="option"
                        tabIndex={0}
                        aria-selected={idx === currIdx}
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <strong className="requestForm__suggestion-main">
                          {main_text}
                        </strong>
                        <br />
                        <small>{secondary_text}</small>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          <div className="estimates__grid estimates__grid__last-row">
            <span>Estimated Cost: </span>
            <span className="estimates__grid__right">
              ${calculateCost(sqft)}
            </span>
          </div>

          <button
            type="reset"
            className="btn estimates__btn"
            style={{
              visibility:
                (interiorChecked || exteriorChecked) &&
                sqft !== 0 &&
                address !== "" &&
                (revitModelChecked ||
                  floorPlansElevationsChecked ||
                  pointCloudChecked ||
                  matterportChecked ||
                  aerialChecked)
                  ? "visible"
                  : "hidden",
            }}
            onClick={() => {
              try {
                axios.post(
                  `${process.env.GATSBY_firebase_function_domain}/smtpMailers-sendMail`,
                  {
                    destination: "info@roboticimaging.com",
                    cc: process.env.GATSBY_env === "prod" ? mailingList : [],
                    subject: `${company} Pricing Estimate`,
                    text: `
										Scan Type: ${
                      interiorChecked && exteriorChecked
                        ? "Interior & Exterior"
                        : interiorChecked
                        ? "Interior"
                        : "Exterior"
                    }
		
										Site Address: ${address}
		
										Deliverables Chosen:
										${revitModelChecked ? "3D Revit Model" : ""}
										${floorPlansElevationsChecked ? "2D FloorPlans & Elevations" : ""}
										${pointCloudChecked ? "Point Cloud" : ""}
										${matterportChecked ? "Matterport Virtual Tour" : ""}
										${aerialChecked ? "Aerial Drone Tour" : ""}
		
										Square Feet Estimate: ${sqft}
										Price Estimate: ${calculateCost(sqft)}
										`,
                    html: `
										<p>Scan Type: ${
                      interiorChecked && exteriorChecked
                        ? "Interior & Exterior"
                        : interiorChecked
                        ? "Interior"
                        : "Exterior"
                    }</p>
		
										<p>Site Address: ${address}</p>
		
										<ul> Deliverables Chosen:
											${revitModelChecked ? "<li>3D Revit Model</li>" : ""}
											${floorPlansElevationsChecked ? "<li>2D FloorPlans & Elevations</li>" : ""}
											${pointCloudChecked ? "<li>Point Cloud</li>" : ""}
											${matterportChecked ? "<li>Matterport Virtual Tour</li>" : ""}
											${aerialChecked ? "<li>Aerial Drone Tour</li>" : ""}
										</ul>
		
										<p>Square Feet Estimate: ${sqft}</p>
										<p>Price Estimate: ${calculateCost(sqft)}</p>
										`,
                  }
                );
                uncheckAll();
                setInteriorChecked(false);
                setExteriorChecked(false);
                setValue("");
              } catch (err) {
                console.log(err);
              }
            }}
          >
            Submit Project
          </button>
        </div>
      </form>
    </div>
  );
};